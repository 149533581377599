import { $get } from '@/assets/js/authRequest'
import { message } from 'antd'

export async function getRequest (id, begintime, endtime) {
  const res = await $get(`/api/xapc/arbitrator/portrait/${id}`, {
    params: {
      begintime, endtime
    }
  })
  if (res.status !== 1) {
    message.error(res.message)
    throw new Error(res.message)
  }
  return res.data
}
