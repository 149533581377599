import React, { useMemo } from 'react'
import ReactEchartsCore from 'echarts-for-react/lib/core'
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'
import style from './Index.module.less'

const y = ['首席', '独任', '边裁']

function getOptions (data, total) {
  return {
    title: {
      show: true,
      text: `案件数：${total} 件`,
      left: '15',
      top: '4%'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      top: '5%'
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: y,
        axisLabel: {
          color: '#333'
        },
        axisLine: {
          lineStyle: {
            color: '#ddd',
            type: 'dashed'
          }
        },
        axisTick: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        minInterval: 1,
        splitLine: {
          lineStyle: {
            color: '#ddd',
            type: 'dashed'
          }
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        }
      }
    ],
    series: [
      {
        name: '在办案件',
        type: 'bar',
        stack: 'Ad',
        data: data.在办案件,
        barCategoryGap: '50%',
        color: '#f09f33',
        label: {
          show: true,
          color: '#000',
          fontSize: 14
        }
      },
      {
        name: '已结案件',
        type: 'bar',
        stack: 'Ad',
        data: data.已结案件,
        barCategoryGap: '50%',
        color: '#5e9df9',
        label: {
          show: true,
          color: '#000',
          fontSize: 14,
          formatter: p => p.value ? p.value : ''
        }
      }
    ]
  }
}

function getMap (l) {
  const o = {}
  l.forEach(li => {
    o[li.name] = li.casenum
  })
  return [o.首席, o.独任, o.边裁]
}

function Main ({ data }) {
  const dataMap = useMemo(() => {
    return {
      在办案件: getMap(data.caseings),
      已结案件: getMap(data.caseclosures)
    }
  }, [data])
  const total = useMemo(() => {
    return data.caseallNum || 0
  }, [data])
  const options = useMemo(() => {
    return getOptions(dataMap, total)
  }, [dataMap, total])
  return (
    <div className={style.container}>
      <ReactEchartsCore echarts={echarts} option={options} style={{ width: '100%', height: '100%' }} />
    </div>
  )
}

export default Main
