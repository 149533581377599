import React, { useMemo } from 'react'
import ReactEchartsCore from 'echarts-for-react/lib/core'
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/tooltip'
import style from './Index.module.less'
import { Empty, Tooltip } from 'antd'
import { colors } from '@/assets/js/config'

function getOptions (list, total) {
  return {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)'
    },
    legend: {
      show: false
    },
    series: [
      {
        name: '立案情况',
        type: 'pie',
        radius: ['50%', '70%'],
        itemStyle: {
          borderWidth: 5,
          borderType: 'solid',
          borderColor: 'rgba(255, 255, 255, 1)'
        },
        label: {
          show: false,
          position: 'center',
          formatter: `{a|案件数}\n{b|${total}件}`,
          rich: {
            a: {
              color: '#7E889C',
              lineHeight: 20,
              fontSize: 16
            },
            b: {
              color: '#1F2229',
              lineHeight: 40,
              fontSize: 24
            }
          }
        },
        data: list.map((li, i) => {
          const d = {
            ...li,
            itemStyle: {
              color: colors[i]
            }
          }
          if (i === 0) {
            d.label = {
              show: true
            }
          }
          return d
        })
      }
    ]
  }
}

function getPercent (v, total) {
  if (v && total) {
    return Math.round(v / total * 10000) / 100 + '%'
  }
}

function formatStr (s) {
  return s.length > 5 ? s.slice(0, 5) + '...' : s
}

function renderList (list, total) {
  return list.map((li, i) => {
    const p = getPercent(li.value, total)
    return (
      <li className={style.li} key={i}>
        <span className={style['li-title']}>
          <span className={style['li-tag']} style={{ backgroundColor: colors[i] }} />
          <span className={style['li-title__text']}>
            <Tooltip title={li.name}>
              {formatStr(li.name)}
            </Tooltip>
          </span>
        </span>
        <span className={style['li-value']}>
          <span className={style['li-value__percent']}>{p}</span>
          <span className={style['li-value__split']} />
          <span className={style['li-value__text']}>{li.value}</span>
        </span>
      </li>
    )
  })
}

function Main ({ data }) {
  const list = useMemo(() => {
    return data.caseCauseaction.map(li => ({
      name: li.name,
      value: li.casenum
    }))
  }, [data])
  const total = useMemo(() => {
    let t = 0
    data.caseCauseaction.forEach(li => {
      t += li.casenum
    })
    return t
  }, [data])
  const options = useMemo(() => {
    return getOptions(list, total)
  }, [list, total])
  if (data.caseCauseaction.length === 0) {
    return <div className={style.empty}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
  }
  return (
    <div className={style.container}>
      <div className={style.chart}>
        <ReactEchartsCore echarts={echarts} option={options} style={{ width: '100%', height: '100%' }} />
      </div>
      <div className={style['list-box']}>
        <ul className={style.list}>
          {renderList(list, total)}
        </ul>
      </div>
    </div>
  )
}

export default Main
