// 仲裁员管理
import React from 'react'
import LazyLoad from '../../LazyLoad'

const ArbitratorList = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorList/Index')} {...props} />
const ArbitratorFee = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorFee/Index')} {...props} />
const ArbitratorMgt = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorManage/Index')} {...props} />
const ArbitratorEdit = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorEdit/Index')} {...props} />
const ArbitratorDetail = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorDetail/Index')} {...props} />
const UnsubmitRemuneration = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/UnsubmitRemuneration/Index')} {...props} />
const MyUnsubmitRemuneration = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/MyUnsubmitRemuneration/Index')} {...props} />
const ArbitratorApplyAudit = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorApplyAudit/Index')} {...props} />
const ArbitratorFeePreview = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorFeePreview/Index')} {...props} />
const ArbitratorFeePay = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorFeePay/Index')} {...props} />
const ApplicationMgt = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ApplicationMgt/Index')} {...props} />
const ArbitratorAccountManage = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorAccountManage/Index')} {...props} />
const XAArbitratorMgt = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/XAArbitratorMgt/Index')} {...props} />
const MessagesSendMgt = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/MessagesSendMgt/Index')} {...props} />
const MessagesSendDetail = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/MessagesSendDetail/Index')} {...props} />
const ArbitratorHearing = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorHearing')} {...props} />
const EvaluateConfigMgt = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorEvaluateConfig/Index')} {...props} />
const ArbitratorObserve = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorObserve/Index')} {...props} />
const ArbitratorListVersion = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorListVersion/Index')} {...props} />
const CaseEvaluateStat = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/CaseEvaluateStat/Index')} {...props} />
const CaseEvaluateStat2 = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/CaseEvaluateStat2/Index')} {...props} />
const ArbitratorAnnual = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorAnnual/Index')} {...props} />
const ArbitratorVersionDetail = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorVersionDetail/Index')} {...props} />
const ArbitratorAnnualDetail = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorAnnualDetail/Index')} {...props} />
const ArbitratorListPro = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorListPro/Index')} {...props} />

const router = {
  ArbitratorList, // 仲裁员列表
  ArbitratorFee, // 仲裁员酬金
  ArbitratorMgt, // 仲裁员管理
  ArbitratorEdit, // 仲裁员编辑
  ArbitratorDetail, // 仲裁员详情
  UnsubmitRemuneration, // 未报报酬
  MyUnsubmitRemuneration, // 我的未报酬金
  ArbitratorApplyAudit, // 仲裁员申请审核
  ArbitratorFeePreview, // 仲裁员酬金推送预览
  ArbitratorFeePay, // 仲裁员酬金推送记录
  ApplicationMgt, // 续聘管理
  ArbitratorAccountManage, // 仲裁员账号管理
  XAArbitratorMgt, // 厦管仲裁员管理
  MessagesSendMgt, // 批量发送信息
  MessagesSendDetail, // 发送消息详情
  ArbitratorHearing, // 仲裁员开庭情况
  EvaluateConfigMgt, // 仲裁员评估配置
  ArbitratorObserve, // 仲裁员观察名单
  ArbitratorListVersion, // 仲裁员电子名册
  CaseEvaluateStat, // 仲裁员评价统计
  CaseEvaluateStat2, // 仲裁员评价可视化
  ArbitratorAnnual, // 仲裁员年审
  ArbitratorVersionDetail, // 仲裁员电子名册详情
  ArbitratorAnnualDetail, // 仲裁员年审详情
  ArbitratorListPro // 仲裁员数字化展示
}

export default router
