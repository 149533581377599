import React, { useMemo, useState } from 'react'
import style from './Index.module.less'
import classNames from 'classnames'
import ReactEchartsCore from 'echarts-for-react/lib/core'
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'
import { transformSecond } from '@/assets/js/tool'

function getOptions (type, data) {
  let t = 0
  let n = 0
  data.$medias.forEach(o => {
    t += o.sum_time
    n += o.c
  })
  return {
    title: {
      show: true,
      text: `观看视频：${n} 次      观看时长：${transformSecond(t)}`,
      left: '15',
      top: '4%',
      textStyle: {
        fontSize: 14
      }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: data.$medias.map(li => li.name),
        axisLabel: {
          color: '#333'
        },
        axisLine: {
          lineStyle: {
            color: '#ddd',
            type: 'dashed'
          }
        },
        axisTick: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        minInterval: 1,
        splitLine: {
          lineStyle: {
            color: '#ddd',
            type: 'dashed'
          }
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        }
      }
    ],
    series: [
      {
        name: type,
        type: 'bar',
        data: data.$medias.map(li => type === '观看次数' ? li.c : li.sum_time),
        barCategoryGap: '50%',
        color: type === '观看次数' ? '#5e9df9' : '#f09f33',
        label: {
          show: true,
          color: '#000',
          formatter: p => {
            const v = p.value
            if (!v) {
              return ''
            }
            return type === '观看次数' ? v : transformSecond(v)
          }
        }
      }
    ]
  }
}

function Main ({ data }) {
  const [current, setCurrent] = useState('观看次数')
  const options = useMemo(() => {
    return getOptions(current, data)
  }, [data, current])
  return (
    <div className={style.block}>
      <div className={style['block-header']}>
        <a className={classNames(style['block-title'], { [style.active]: current === '观看次数' })} onClick={() => setCurrent('观看次数')}>观看次数</a>
        <a className={classNames(style['block-title'], { [style.active]: current === '观看时长' })} onClick={() => setCurrent('观看时长')}>观看时长</a>
      </div>
      <div className={style['block-inner']}>
        <ReactEchartsCore echarts={echarts} option={options} style={{ width: '100%', height: '100%' }} />
      </div>
    </div>
  )
}

export default Main
