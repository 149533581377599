import { $get } from '@/assets/js/authRequest'
import { message } from 'antd'

export async function getListRequest (arbitratorid) {
  const res = await $get('/api/xapc/arbitratorAnnual/getArbitratorAnnual', {
    params: {
      arbitratorid
    }
  })
  if (res.status !== 1) {
    message.error(res.message)
    throw new Error(res.message)
  }
  return res.data
}

export async function getConfigRequest () {
  const res = await $get('/api/xapc/bizconfig/getByRule', {
    params: {
      rule: '仲裁员年审信息字段'
    }
  })
  if (res.status !== 1) {
    message.error(res.message)
    throw new Error(res.message)
  }
  return res.data
}
