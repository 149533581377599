import React from 'react'
import style from './Index.module.less'
import { Empty, Tooltip } from 'antd'

function Main ({ data }) {
  return (
    <div className={style.container}>
      {
        !data || !data.caseProcesser || data.caseProcesser.length === 0 ? (
          <div className={style.empty}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
        ) : (
          <>
            <div className={style.line}>
              <div className={style.label}>姓名</div>
              <div className={style.value}>次</div>
            </div>
            {
              data.caseProcesser.map((li, i) => {
                return (
                  <div className={style.line} key={i}>
                    <div className={style.label}>
                      <Tooltip>
                        <span className={style['label-text']}>{li.name}</span>
                      </Tooltip>
                    </div>
                    <div className={style.value}>{li.casenum}</div>
                  </div>
                )
              })
            }
          </>
        )
      }
    </div>
  )
}

export default Main
