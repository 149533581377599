import React, { useEffect, useMemo, useState } from 'react'
import { getListRequest, getConfigRequest } from './ajax'
import { awaitWrap } from '@/assets/js/tool'
import { message, Table } from 'antd'
import Common from '@/assets/js/common'
import { getColumns } from './util'
import style from './Index.module.less'

async function initList (id, setList, setLoading) {
  setLoading(true)
  const [e, d] = await awaitWrap(getListRequest(id))
  setLoading(false)
  if (e === null) {
    setList(d.map((li, i) => {
      li.index = i + 1
      return li
    }))
  }
}

async function getConfig (setParams) {
  const h = message.loading('加载中', 120)
  const [e, d] = await awaitWrap(getConfigRequest())
  h()
  if (e === null && d !== null) {
    setParams(d.default_value.filter(o => d.value.includes(o.id)).map(li => ({ label: li.title, value: li.value })))
  }
}

function Main ({ id }) {
  const common = new Common()
  const [params, setParams] = useState([])
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const paramMap = useMemo(() => {
    const o = {}
    params.forEach(li => {
      o[li.value] = li.label
    })
    return o
  }, [params])
  useEffect(() => {
    if (id) {
      initList(id, setList, setLoading)
    }
  }, [id])
  useEffect(() => {
    getConfig(setParams)
  }, [])
  return (
    <div>
      <div className={style.title}>年审情况</div>
      <div className={style.container}>
        <Table rowKey='id' columns={getColumns(common, paramMap)} loading={loading} dataSource={list} pagination={false} />
      </div>
    </div>
  )
}

export default Main
