// 桌面
import React from 'react'
import LazyLoad from '../../LazyLoad'

const BusinessDesktop = (props) => <LazyLoad loadFn={() => import('../../../pages/desktop/BusinessDesktop')} {...props} />
const AcceptDesktop = (props) => <LazyLoad loadFn={() => import('../../../pages/desktop/AcceptDesktop')} {...props} />
const LeaderDesktop = (props) => <LazyLoad loadFn={() => import('../../../pages/desktop/LeaderDesktop')} {...props} />
const SecretaryGeneralDesktop = (props) => <LazyLoad loadFn={() => import('../../../pages/desktop/SecretaryGeneralDesktop')} {...props} />
const DeputySecretaryGeneralDesktop = (props) => <LazyLoad loadFn={() => import('../../../pages/desktop/DeputySecretaryGeneralDesktop')} {...props} />
const DeputySecretaryGeneralDesktop2 = (props) => <LazyLoad loadFn={() => import('../../../pages/desktop/DeputySecretaryGeneralDesktop2')} {...props} />
const DefaultDesktop = (props) => <LazyLoad loadFn={() => import('../../../pages/desktop/DefaultDesktop')} {...props} />
const SendDesktop = (props) => <LazyLoad loadFn={() => import('../../../pages/desktop/SendDesktop')} {...props} />
const FinancialDesktop = (props) => <LazyLoad loadFn={() => import('../../../pages/desktop/FinancialDesktop')} {...props} />
const CaseManageDesktop = (props) => <LazyLoad loadFn={() => import('../../../pages/desktop/CaseManageDesktop/Index')} {...props} />
const RegisterManageDesktop = (props) => <LazyLoad loadFn={() => import('../../../pages/desktop/RegisterManageDesktop/Index')} {...props} />
const ZoneManageDesktop = (props) => <LazyLoad loadFn={() => import('../../../pages/desktop/ZoneManageDesktop/Index')} {...props} />

const router = {
  AcceptDesktop, // 收案秘书桌面
  BusinessDesktop, // 办案秘书桌面
  LeaderDesktop, // 领导桌面
  SecretaryGeneralDesktop, // 秘书长桌面
  DeputySecretaryGeneralDesktop, // 副秘书长桌面
  DeputySecretaryGeneralDesktop2, // 副秘书长桌面
  DefaultDesktop, // 默认桌面
  SendDesktop, // 送达秘书桌面
  FinancialDesktop, // 财务桌面
  CaseManageDesktop, // 案件负责人桌面
  ZoneManageDesktop, // 区域负责人桌面
  RegisterManageDesktop // 立案处负责人桌面

}

export default router
