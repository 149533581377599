// import { formatDate } from '@/assets/js/tool'
import React from 'react'
import { Space } from 'antd'
import { formatDate } from '@/assets/js/tool'
import moment from 'moment'
export function getColumns (common, params) {
  return [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: '60px'
    },
    {
      title: '开始时间',
      dataIndex: 'annual_datetime',
      key: 'annual_datetime',
      width: 150,
      render: (v) => {
        return formatDate(v)
      }
    },
    {
      title: '结束时间',
      dataIndex: 'annual_end_datetime',
      key: 'annual_end_datetime',
      width: 150,
      render: (v) => {
        return formatDate(v)
      }
    },
    {
      title: '审核字段',
      dataIndex: 'annual_field',
      key: 'annual_field',
      width: 150,
      render: (v) => {
        if (typeof v === 'string') {
          const l = v.split(',').filter(li => li)
          return l.map(k => params[k]).filter(li => li).join(',')
        }
      }
    },
    {
      title: '描述',
      dataIndex: 'content',
      key: 'content',
      width: 150
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: 150
    },
    {
      title: '操作',
      dataIndex: 'id',
      key: 'id',
      width: 250,
      render: (v, l) => {
        const range = []
        if (l.annual_datetime) {
          range.push(moment(l.annual_datetime * 1000).format('YYYY年MM月DD日'))
        }
        if (l.annual_end_datetime) {
          range.push(moment(l.annual_end_datetime * 1000).format('YYYY年MM月DD日'))
        }
        return (
          <Space>
            <a onClick={() => { common.addWindow({ id: `ArbitratorAnnualDetail-${v}`, title: `仲裁员年审-${range.join('~')}`, componentName: 'ArbitratorAnnualDetail', meta: { id: v } }) }}>详情</a>
          </Space>
        )
      }
    }
  ]
}
