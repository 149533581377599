import React from 'react'
import LazyLoad from '../../LazyLoad'
import desktop from './desktop' // 桌面
import filingCaseManage from './filingCaseManage' // 立案管理
import handlingCaseManage from './handlingCaseManage' // 办案管理
import sendCaseManage from './sendCaseManage' // 送达管理
import caseManage from './caseManage' // 案件管理
import adminManage from './adminManage' // 后台管理
import arbitratorManage from './arbitratorManage' // 仲裁员管理
import payManage from './payManage' // 交费管理
import statisticManage from './statisticManage' // 统计管理
import courtManage from './courtManage' // 中院
import trainManage from './trainManage' // 中院

// const CaseDetail = (props) => <LazyLoad loadFn={() => import('../../../pages/CaseDetail')} {...props} />
const CaseDetail = (props) => <LazyLoad loadFn={() => import('@/pages/CaseDetail/Index')} {...props} />

const PdfReader = (props) => <LazyLoad loadFn={() => import('../../../pages/common/PdfReader')} {...props} />
const PdfSignReader = (props) => <LazyLoad loadFn={() => import('../../../pages/common/PdfSignReacder/Index')} {...props} />

const MessageList = (props) => <LazyLoad loadFn={() => import('../../../pages/common/MessageList')} {...props} />
const MyMessageList = (props) => <LazyLoad loadFn={() => import('../../../pages/common/MyMessageList')} {...props} />

export default {
  ...desktop, // 桌面
  ...filingCaseManage, // 立案管理
  ...handlingCaseManage, // 办案管理
  ...sendCaseManage, // 送达管理
  ...caseManage, // 案件管理
  ...adminManage, // 后台管理
  ...arbitratorManage, // 仲裁员管理
  ...payManage, // 交费管理
  ...statisticManage, // 统计管理
  ...courtManage, // 中院对接
  ...trainManage, // 培训相关
  PdfReader, // pdf阅读器
  CaseDetail, // 案件详情
  MessageList, // 消息列表
  PdfSignReader, // pdf签名
  MyMessageList // 我的案件通知
}
