import React, { useEffect, useMemo, useState } from 'react'
import style from './Index.module.less'
import { getRequest } from './ajax'
import { awaitWrap, formatMoney } from '@/assets/js/tool'
import { Space, DatePicker, Row, Col, Skeleton } from 'antd'
import moment from 'moment'
import classNames from 'classnames'
import BlockOne from './components/BlockOne/Index'
import BlockTwo from './components/BlockTwo/Index'
import BlockThree from './components/BlockThree/Index'
import BlockFour from './components/BlockFour/Index'
import BlockFive from './components/BlockFive/Index'

async function init (id, year, setData, setLoading) {
  setLoading(true)
  const begin = Math.floor(moment(`${year}-01-01 00:00:00`).valueOf() / 1000)
  const end = Math.floor(moment(`${year}-12-31 23:59:59`).valueOf() / 1000)
  const [e, d] = await awaitWrap(getRequest(id, begin, end))
  setLoading(false)
  if (e === null && d !== null) {
    setData(d)
  }
}

function DateCell ({ title, value = 0, unit = '件', right = false }) {
  return (
    <div className={classNames(style['header-data-cell'], { [style.right]: right })}>
      <div className={style['header-data-cell-title']}>{title}</div>
      <div className={style['header-data-cell-value']}>
        <span className={style['header-data-cell-value-text']}>{value}</span>
        <span className={style['header-data-cell-value-split']}>/</span>
        <span className={style['header-data-cell-value-unit']}>{unit}</span>
      </div>
    </div>
  )
}

function Block ({ title, children }) {
  return (
    <div className={style.block}>
      <div className={style['block-header']}>
        <span className={style['block-title']}>{title}</span>
      </div>
      <div className={style['block-inner']}>
        {children}
      </div>
    </div>
  )
}

function Main ({ id, name }) {
  const [data, setData] = useState()
  const [year, setYear] = useState(moment().format('YYYY'))
  const [loading, setLoading] = useState(false)
  const date = useMemo(() => {
    return moment(`${year}-01-01 00:00:00`)
  }, [year])
  const money = useMemo(() => {
    if (data && data.remuneration && data.remuneration.real_amount_sum) {
      const s = Number(data.remuneration.real_amount_sum)
      return isNaN(s) ? '0' : formatMoney(Math.round(s * 100) / 100)
    }
    return '0'
  }, [data])
  useEffect(() => {
    if (id) {
      init(id, year, setData, setLoading)
    }
  }, [id, year])
  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style['header-title-line']}>
          <span className={style['header-title']}>{name}</span>
          {/* <Button type='primary'>下载PDF</Button> */}
        </div>
        <div className={style['header-first-line']}>
          <Space>
            <span className={style['year-title']}>仲裁员年报</span>
            <DatePicker picker='year' value={date} onChange={e => { setYear(e.format('YYYY')) }} />
          </Space>
        </div>
        {
          data && (
            <div className={style['header-data-line']}>
              <Space split={<div className={style['header-data-cell-split']} />}>
                <DateCell title='共分配' value={data.caseallNum} />
                <DateCell title='在办' value={data.caseingNum} />
                <DateCell title='已结' value={data.caseclosuresNum} />
                <DateCell title='仲裁员负面评价' unit='次' value={data.caseEvaluateNum} />
                <DateCell title='参加培训情况' value={data.mediaNum} unit='次' />
              </Space>
              <DateCell title='发放酬金' unit='￥' value={money} right />
            </div>
          )
        }
      </div>
      <div className={style.line}>
        <Row gutter={24}>
          <Col span={12}>
            <Block title='仲裁庭角色'>
              <Skeleton loading={loading} className={style.loading}>
                {data && <BlockOne data={data} />}
              </Skeleton>
            </Block>
          </Col>
          <Col span={12}>
            <Block title='案由分布'>
              <Skeleton loading={loading} className={style.loading}>
                {data && <BlockTwo data={data} />}
              </Skeleton>
            </Block>
          </Col>
        </Row>
      </div>
      <div className={style.line}>
        <Row gutter={24}>
          <Col span={12}>
            <Block title='仲裁员负面评价'>
              <Skeleton loading={loading} className={style.loading}>
                {data && <BlockThree data={data} />}
              </Skeleton>
            </Block>
          </Col>
          <Col span={12}>
            <Block title='配合秘书'>
              <Skeleton loading={loading} className={style.loading}>
                {data && <BlockFour data={data} />}
              </Skeleton>
            </Block>
          </Col>
        </Row>
      </div>
      <div className={style.line}>
        <Skeleton loading={loading} className={style.loading}>
          {data && <BlockFive data={data} />}
        </Skeleton>
      </div>
    </div>
  )
}

export default Main
