// 统计管理
import React from 'react'
import LazyLoad from '../../LazyLoad'

const CaseStatistic = (props) => <LazyLoad loadFn={() => import('../../../pages/statisticManage/CaseStatistic')} {...props} />
const FinanceStatistic = (props) => <LazyLoad loadFn={() => import('../../../pages/statisticManage/FinanceStatistic')} {...props} />
const YearStatistic = (props) => <LazyLoad loadFn={() => import('../../../pages/statisticManage/YearStatistic')} {...props} />
const MonthStatistic = (props) => <LazyLoad loadFn={() => import('../../../pages/statisticManage/MonthStatistic')} {...props} />
const SecretaryStatistic = (props) => <LazyLoad loadFn={() => import('../../../pages/statisticManage/SecretaryStatistic')} {...props} />
const ArbitratorStatistic = (props) => <LazyLoad loadFn={() => import('../../../pages/statisticManage/ArbitratorStatistic')} {...props} />
const ArbitratorCaseStatistic = (props) => <LazyLoad loadFn={() => import('../../../pages/statisticManage/ArbitratorCaseStatistic')} {...props} />
const AllCauseActionStatistic = (props) => <LazyLoad loadFn={() => import('../../../pages/statisticManage/AllCauseActionStatistic')} {...props} />
const SuspensionManage = (props) => <LazyLoad loadFn={() => import('../../../pages/statisticManage/SuspensionManage')} {...props} />
const SuspensionManageFuzhou = (props) => <LazyLoad loadFn={() => import('../../../pages/statisticManage/SuspensionManageFuzhou')} {...props} />
const SuspensionManageBenhui = (props) => <LazyLoad loadFn={() => import('../../../pages/statisticManage/SuspensionManageBenhui')} {...props} />
const MySuspensionManage = (props) => <LazyLoad loadFn={() => import('../../../pages/statisticManage/MySuspensionManage')} {...props} />
const FinanceCaseStatistic = (props) => <LazyLoad loadFn={() => import('../../../pages/statisticManage/FinanceCaseStatistic/Index')} {...props} />
const CaseTimeStatistic = (props) => <LazyLoad loadFn={() => import('../../../pages/statisticManage/CaseTimeStatistic/Index')} {...props} />
const HsDockingStatAudit = (props) => <LazyLoad loadFn={() => import('../../../pages/statisticManage/HsDockingStatAudit/Index')} {...props} />
const HsDockingStatAuditDetail = (props) => <LazyLoad loadFn={() => import('../../../pages/statisticManage/HsDockingStatAuditDetail/Index')} {...props} />
const CaseStatList = (props) => <LazyLoad loadFn={() => import('../../../pages/statisticManage/CaseStatList/Index')} {...props} />
const CaseBillStatistic = (props) => <LazyLoad loadFn={() => import('../../../pages/statisticManage/CaseBillStatistic')} {...props} />

const router = {
  CaseStatistic, // 案件统计
  FinanceStatistic, // 金融受案统计
  YearStatistic, // 年度统计
  MonthStatistic, // 月份统计
  SecretaryStatistic, // 秘书统计
  ArbitratorStatistic, // 仲裁员统计
  ArbitratorCaseStatistic, // 仲裁员案件统计
  AllCauseActionStatistic, // 全委案由统计
  SuspensionManage, // 中止案件列表
  SuspensionManageFuzhou, // 中止案件列表福州厅
  SuspensionManageBenhui, // 中止案件列表本会
  MySuspensionManage, // 我的中止案件列表
  FinanceCaseStatistic, // 金融案件统计
  CaseTimeStatistic, // 案件时间统计
  HsDockingStatAudit, // 海丝统计推送
  HsDockingStatAuditDetail, // 海丝统计推送详情
  CaseStatList, // 案件统计明细
  CaseBillStatistic // 案件费用分类统计
}

export default router
